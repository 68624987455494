<template>
<div class="modal__background">
  <button id="modal-close-button" class="modal__close-button"></button>
  <div class="modal__header">
      <p class="font-news-text font-semibold modal__header-text">
        {{$t('Проверить мои инвестиции')}}
      </p>
  </div>
  <div class="modal__content">
      <div class="modal__content-inner">
          <div class="modal-investment__info-block">
              <div
                  class="modal-investment__subblock-title font-semibold font-body-text font-color-main-header">
                  {{$t('Баланс')}}
              </div>
              <div class="modal-investment__row modal-investment__row--title">
                  <div class="d-flex font-color-main-header ta-c font-lr-title font-bold">
                      <div class="modal-investment__row-icon">
                          <img
                            src="../assets/icons/token-small.svg"
                            alt="token"
                          >
                      </div>
                      <span>
                        NYS
                      </span>
                  </div>
                  <div class="font-color-main-header font-lr-title font-bold">
                      {{(investedSummaryBalanceFormatted)}} NYS
                  </div>
              </div>

              <div class="modal-investment__row">
                  <div class="font-body-text-small font-color-main-header ta-c font-medium">
                      {{ tokenPriceFormatted }}
                  </div>
                  <div class="font-color-main-header font-body-text-small font-medium">
                    {{ investedSummaryUSDTBalance }} USDT
                  </div>
              </div>

              <div class="modal-investment__row">
                  <div class="font-color-main-header ta-c font-medium font-body-text-small">
                      {{$t('Сеть')}}
                  </div>
                  <div class="font-color-main-header font-body-text-small font-semibold">
                    {{isConnected && isCorrectNetwork ? 'BEP-20' : "-"}}
                  </div>
              </div>

              <div class="modal-investment__row">
                  <div class="font-body-text-small font-color-main-header ta-c font-medium">
                      {{$t('Адрес')}}
                  </div>
                  <div
                    v-if="isConnected"
                    class="font-body-text-small font-color-main-header d-flex al-c font-semibold"
                  >
                    <span id="copy-value">{{isConnected && isCorrectNetwork ? abbreviatedWalletAddress : "-"}}</span>
                    <img
                      class="modal-investment__copy"
                      src="../assets/icons/copy.svg"
                      id="copyAddressButton"
                      @click="() => copyToClipboard(walletAddress)"
                      alt="copy button icon"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div
                    v-else
                    class="font-body-text-small font-color-main-header d-flex al-c font-semibold"
                  >
                    -
                  </div>
              </div>

              <div class="modal-investment__row">
                  <div class="font-body-text-small font-color-main-header ta-c font-medium">
                      NYS {{$t('Разблокировано')}}
                  </div>
                  <div class="font-body-text-small font-color-main-header font-semibold">
                    {{investedSummaryUnlockedFormatted}}
                  </div>
              </div>
          </div>

          <div
            class="modal-investment__info-block modal-investment__info-block--mt"
          >
            <div
                class="modal-investment__subblock-title font-semibold font-body-text font-color-main-header">
                {{$t('История покупки')}}
            </div>
            <div class="modal-investment__history">
              <template
                v-if="investmentsList.length"
              >
                <div
                  v-for="investment in investmentsList"
                  :key="investment.id"
                  class="modal-investment__history-block"
                >
                    <div class="modal-investment__row">
                        <div class="font-color-main-header ta-c font-medium font-body-text-small">
                            {{$t('Время покупки')}}
                        </div>
                        <div class="font-body-text-small font-color-main-header font-semibold">
                          {{ getTimeFormatted(investment.timestamp) }}
                        </div>
                    </div>

                    <div class="modal-investment__row">
                        <div class="font-color-main-header ta-c font-medium font-body-text-small">
                            {{$t('Куплено')}}
                        </div>
                        <div class="font-body-text-small font-color-main-header font-semibold">
                          {{ getFormattedTokensValue(investment.amount_nys) }} NYS
                        </div>
                    </div>

                    <div class="modal-investment__row">
                        <div class="font-color-main-header ta-c font-medium font-body-text-small">
                            {{$t('Эквивалент в USDT')}}
                        </div>
                        <div class="font-body-text-small font-color-main-header font-semibold">
                          {{ getFormattedTokensValue(investment.amount_usdt) }} USDT
                        </div>
                    </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="modal-investment__history-block"
                >
                    <div class="modal-investment__row">
                        <div class="font-color-main-header ta-c font-medium font-body-text-small">
                            {{$t('Время покупки')}}
                        </div>
                        <div class="font-body-text-small font-color-main-header font-semibold">
                          -
                        </div>
                    </div>

                    <div class="modal-investment__row">
                        <div class="font-color-main-header ta-c font-medium font-body-text-small">
                            {{$t('Куплено')}}
                        </div>
                        <div class="font-body-text-small font-color-main-header font-semibold">
                          -
                        </div>
                    </div>

                    <div class="modal-investment__row">
                        <div class="font-color-main-header ta-c font-medium font-body-text-small">
                            {{$t('Эквивалент в USDT')}}
                        </div>
                        <div class="font-body-text-small font-color-main-header font-semibold">
                          -
                        </div>
                    </div>
                </div>
              </template>
            </div>
          </div>
      </div>
  </div>
  <div class="modal__footer">
      <button
        v-if="isConnected"
        type="button"
        class="button modal__button font-color-text-secondary font-body-text font-semibold ta-c"
        @click="disconnectWallet"
      >
        {{ $t("Отключить кошелек") }}
      </button>
      <button
        v-else
        type="button"
        class="button modal__button font-color-text-secondary font-body-text font-semibold ta-c"
        @click="connectWallet"
      >
        {{ $t("Подключить кошелек") }}
      </button>
  </div>
  <WarnSnackbar v-if="showSnackbar" :info="{text: $t('Вы подключены к неправильной сети. Пожалуйста, переключитесь на поддерживаемую сеть.'), type: warnTypes.regular}" :btn-name="'Switch'" :callback="changeNetworkToCorrect" />

  <Teleport to="#importtoken">
    <button
      class="buttons-block__btn buttons-block__btn_transparent token-section__btn-vesting font-color-text-secondary font-title font-semibold ta-c"
      @click="importToken"
    >
      {{ $t("Импортировать токен") }}
    </button>
  </Teleport>
</div>
</template>

<script>
/* eslint-disable */
import BigNumber from "bignumber.js";
import WarnSnackbar from "@/components/WarnSnackbar.vue";
import warnTypes from "@/constants/WARN_TYPES.js"
import CONFIG_VARIABLES from "@/constants/CONFIG_VARIABLES.js"
import { modal } from "@/factories/web3/Web3Modal"
import { useSwitchNetwork, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/vue";
import { BrowserProvider, Contract, formatEther } from "ethers";
import moment from "moment";
import ERC20, { ERC20ABI } from "@/factories/web3/ERC20";

export default {
  name: "InvestmentsForm",
  components: {
    WarnSnackbar,
  },
  data() {
    return {
      showSnackbar: false,
      warnTypes,
      CONFIG_VARIABLES,
      isConnected: null,
      walletAddress: null,
      walletProvider: null,
      currentChainID: null,
      userBalance: null,
      initLoading: false,
      currentExplorerUrl: CONFIG_VARIABLES.CORRECT_NETWORK_EXPLORER_URL,
      commonError: "",
      investmentsList: [],
      tokenPrice: null,
      modal: null,
      NYSBalance: null
    };
  },
  created() {
    moment.locale(this.$i18n.locale)
    this.modal = modal;
    const { isConnected, chainId, address } = useWeb3ModalAccount();
    this.isConnected = isConnected;
    this.walletAddress = address;
    this.currentChainID = chainId;
  },
  computed: {
    isCorrectNetwork() {
      return this.currentChainID && this.currentChainID === CONFIG_VARIABLES.CORRECT_NETWORK_ID;
    },

    tokenPriceFormatted() {
      return this.tokenPrice ? `${this.tokenPrice} USDT` : ""
    },

    investedSummaryBalanceFormatted() {
      if (this.isConnected) {
      return !this.NYSBalance ? "-" : new Number(this.NYSBalance).toFixed(2);
      } else {
        return "-"
      }
    },
    
    // investedSummaryBalance() {
    //   if (this.isConnected && this.isCorrectNetwork && this.tokens.length) {
    //     this.userBalance
    //     return tokenBalance
    //   }
    // },
    
    investedSummaryUSDTBalance() {
      if (this.isConnected) {
        return !this.NYSBalance ? "-" : new Number((this.tokenPrice * this.NYSBalance).toFixed(2)).toFixed(2);
      } else {
        return "-"
      }
    },

    investedSummaryUnlockedFormatted() {
      return this.investedSummaryUnlocked ? `${new Number(this.investedSummaryUnlocked).toFixed(2)} NYS` : "-"
    },

    investedSummaryUnlocked() {
      if (this.investmentsList.length) {
        return this.investmentsList[0].amount_unlocked;
      }
    },

    abbreviatedWalletAddress() {
      if (window.innerWidth <= 766) {
        return this.abbreviatedWalletAddressMobile;
      } else {
        return this.abbreviatedWalletAddressDesktop;
      }
    },

    abbreviatedWalletAddressDesktop() {
      return (
        this.walletAddress.substr(0, 6) +
        "..." +
        this.walletAddress.substr(
          this.walletAddress.length - 4,
          this.walletAddress.length
        )
      );
    },

    abbreviatedWalletAddressMobile() {
      return (
        "..." +
        this.walletAddress.substr(
          this.walletAddress.length - 4,
          this.walletAddress.length
        )
      );
    },
  },
  watch: {
    isConnected: {
      deep: true,
      handler(newVal) {
        if (newVal && this.isCorrectNetwork) {
          this.getTokenPrice();
        }
      },
    },
    isCorrectNetwork(newVal) {
      if (this.isConnected) {
        if (newVal) {
          this.showSnackbar = false;
        } else {
          this.showSnackbar = true;
        }
      }
    },
    walletAddress(newVal) {
      if (newVal && this.isConnected && this.isCorrectNetwork) {
        this.getInvestmentsInfo();
      }
    }
  },
  methods: {
    async importToken() {
      if (this.isConnected) {
        try {
          const walletProvider = this.modal.getWalletProvider();
          const res = await walletProvider.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: CONFIG_VARIABLES.NODESYS_CONTRACT_ADDRESS,
                symbol: "NYS",
                decimals: 18,
              },
            },
          });

          console.log("import token res")
          console.log(res)
        } catch (error) {
          console.log("import token error");
          console.log(error.message);
          this.setCommonError(error.message?.message || error.message);
          setTimeout(() => {
            this.resetCommonError()
          }, 3500);
        }
      } else {
        this.connectWallet();
      }
    },

    async getInvestmentsInfo() {
      try {
        this.initLoading = true;
        const userAddr = this.walletAddress;
        // const userAddr = "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81";
        const origin = window.location.origin;
        const cookieToken = document.cookie.match(/csrftoken=[^\s;]+/);
        if (!cookieToken) {
          return;
        }
        console.log("userAddr")
        console.log(userAddr)
        const token = cookieToken[0].split("=")[1]
        const response = await fetch(origin + `/api/transactions/${userAddr}`, {
          method: "GET",
          headers: {
            "X-CSRFToken": token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        // TEST
      //   const data = [
      //     {
      //       "id": 86,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0xa2f41680595e91e53ed9dd13c2df824e367a7ba3f66afad38e22dac171328968",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "49.562682215743440233",
      //       "amount_usdt": "17.000000000000000000",
      //       "price_nys": "0.343000000000000000",
      //       "full_name": "vt",
      //       "email": "dv@df.ru",
      //       "timestamp": 1716875431,
      //       "block_number": 40719213,
      //       "status": "Success",
      //       "promo_code": 5
      //     },
      //     {
      //       "id": 84,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0xfc656f3a2aca96875dc34c5b982d721e37768341da0f02cfe56ef8b1031cc687",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "323.615160349854227405",
      //       "amount_usdt": "111.000000000000000000",
      //       "price_nys": "0.343000000000000000",
      //       "full_name": "Vi",
      //       "email": "Vi@test.ru",
      //       "timestamp": 1716873744,
      //       "block_number": 40718651,
      //       "status": "Success",
      //       "promo_code": 5
      //     },
      //     {
      //       "id": 78,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0x76b3199eb669d5b0b493798166d1e13aeb6cdaa12f749047d5ad9f1212c97ce1",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "32.069970845481049562",
      //       "amount_usdt": "11.000000000000000000",
      //       "price_nys": "0.343000000000000000",
      //       "full_name": "vr",
      //       "email": "vfg@ert.ru",
      //       "timestamp": 1716813453,
      //       "block_number": 40698557,
      //       "status": "Success",
      //       "promo_code": 5
      //     },
      //     {
      //       "id": 77,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0xb2a1d4da37d256849ec431508c5d55ab8d9ff6e70a30a5224f074863f930dc5d",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "29.154518950437317784",
      //       "amount_usdt": "10.000000000000000000",
      //       "price_nys": "0.343000000000000000",
      //       "full_name": "Vi",
      //       "email": "Vu@test.ru",
      //       "timestamp": 1716812751,
      //       "block_number": 40698323,
      //       "status": "Success",
      //       "promo_code": 5
      //     },
      //     {
      //       "id": 75,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0x58507ff9ad39d839da8eda2815ef88b4154c68bc8656c16a963dd4f9089ff617",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "285.714285714285714285",
      //       "amount_usdt": "100.000000000000000000",
      //       "price_nys": "0.350000000000000000",
      //       "full_name": "vi",
      //       "email": "vir@ert.ru",
      //       "timestamp": 1716810786,
      //       "block_number": 40697668,
      //       "status": "Success",
      //       "promo_code": null
      //     },
      //     {
      //       "id": 74,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0x67293f7a4cb005035059c0c7fe17b4012a91f30eeb738a610a027ed170f9a2fc",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "2857.142857142857142857",
      //       "amount_usdt": "1000.000000000000000000",
      //       "price_nys": "0.350000000000000000",
      //       "full_name": "vi",
      //       "email": "vi@test.ru",
      //       "timestamp": 1716807693,
      //       "block_number": 40696637,
      //       "status": "Success",
      //       "promo_code": null
      //     },
      //     {
      //       "id": 73,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0xce47d1287e5547887ead54240d430a989bc959514d8404aa0ae8762d95a8cb6c",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "2857.142857142857142857",
      //       "amount_usdt": "1000.000000000000000000",
      //       "price_nys": "0.350000000000000000",
      //       "full_name": "",
      //       "email": "",
      //       "timestamp": 1716806388,
      //       "block_number": 40696202,
      //       "status": "Success",
      //       "promo_code": null
      //     },
      //     {
      //       "id": 69,
      //       "amount_unlocked": 14906462.973760933,
      //       "transaction_hash": "0xfab917ba9f91b5d90ab9889f1b5d11ba4af8a8468bad233ac07938bfa94ced7e",
      //       "sender_address": "0x49939aeD5D127C2d9a056CA1aB9aDe9F79fa8E81",
      //       "amount_nys": "28.571428571428571428",
      //       "amount_usdt": "10.000000000000000000",
      //       "price_nys": "0.350000000000000000",
      //       "full_name": "",
      //       "email": "",
      //       "timestamp": 1716794081,
      //       "block_number": 40692105,
      //       "status": "Success",
      //       "promo_code": null
      //     }
      // ]
        // const data = []
        // TEST

        if (!response.ok) {
          throw new Error(`Something went wrong: ${response.status}`);
        }

        const data = await response.json();
        console.log("investments list data")
        console.log(data)
        this.investmentsList = data;
      } catch (error) {
        console.error(error.message);
        return null;
      } finally {
        this.initLoading = false;
      }
    },
    async getTokenPrice() {
      const walletProvider = this.modal.getWalletProvider();
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const presaleContractAddress = CONFIG_VARIABLES.PRESALE_CONTRACT_ADDRESS;
      const presaleABI = CONFIG_VARIABLES.PRESALE_CONTRACT_ABI;
      const presaleContract = new Contract(presaleContractAddress, presaleABI, signer);
      const currentPriceWei = await presaleContract.priceNYS();
      console.log("currentPriceWei")
      console.log(currentPriceWei)
      // const minAmount = await presaleContract.maximalAmount();
      // const maxAmount = await presaleContract.minimalAmount();
      const tokenPrice = formatEther(currentPriceWei);
      this.tokenPrice = Number(tokenPrice);
      // const minVal = formatEther(minAmount);
      // const maxVal = formatEther(maxAmount);
      // this.maximumAmount = Number(minVal).toFixed();
      // this.minimumAmount = Number(maxVal).toFixed();
      const ERC20_ABI = ERC20ABI;
      const USDTErc20Contract = new Contract(CONFIG_VARIABLES.USDT_CONTRACT_ADDRESS, ERC20_ABI, signer);
      const USDTBalance = await USDTErc20Contract.balanceOf(this.walletAddress);
      const USDTBalanceNumber = Number(USDTBalance);
      const USDTDecimals = await USDTErc20Contract.decimals();
      const USDTDecimalsNumber = Number(USDTDecimals);
      this.userBalance = new BigNumber(USDTBalanceNumber)
        .div(new BigNumber(10).pow(USDTDecimalsNumber))
        .toFixed();
      const NYSErc20Contract = new Contract(CONFIG_VARIABLES.NODESYS_CONTRACT_ADDRESS, ERC20_ABI, signer);
      const NYSBalance = await NYSErc20Contract.balanceOf(this.walletAddress);
      const NYSBalanceNumber = Number(NYSBalance);
      const NYSDecimals = await NYSErc20Contract.decimals();
      const NYSDecimalsNumber = Number(NYSDecimals);
      this.NYSBalance = new BigNumber(NYSBalanceNumber)
        .div(new BigNumber(10).pow(NYSDecimalsNumber))
        .toFixed();
    },
    clearInvestmentsInfo() {
      this.investmentsList = [];
    },
    getFormattedTokensValue(val) {
      return new Number(val).toFixed(2)
    },
    getTimeFormatted(timestamp) {
      return moment.unix(timestamp).format('DD MMM YYYY');
    },
    connectWallet() {
      this.modal.open();
    },
    disconnectWallet() {
      this.modal.disconnect();
      this.clearInvestmentsInfo();
    },
    copyToClipboard(val) {
      navigator.clipboard.writeText(val);
      document.getElementById('copy-btn').classList.toggle("clicked");
      setTimeout(function () {
        document.getElementById('copy-btn').classList.remove("clicked");
      }, 1000);
    },
    async changeNetworkToCorrect() {
      this.initLoading = true;
      const { switchNetwork } = useSwitchNetwork();
      await switchNetwork(CONFIG_VARIABLES.CORRECT_NETWORK_ID);
      this.initLoading = false;
    },
  },
};
</script>

<style lang="scss">
.transaction-error {
  margin-top: 16px;
  color: #f76487;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
}
</style>
