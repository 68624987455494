import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import RU from "./locale/ru.json";
import EN from "./locale/en.json";
import store from "./store";
import App from "./App.vue";

const app = createApp(App);
const lang = localStorage.getItem('changeLanguageVar') || 'en'
try {
  console.log(lang)
} catch (e) {
  console.log("lang error")
  console.log(e)
}
const i18n = createI18n({
  locale: lang,
  messages: {
    ru: RU,
    en: EN,
  },
});
app.use(store);
app.use(i18n);
app.mount("#app");
